.myoverlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.75);
	/* display:flex;
	align-items: center;
	justify-content: center; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.close__modal {
	position: absolute;
	top: 0px;
	right: 0px;
}

.center_title {
	position: absolute;
	top: 10px;
	left: 50vh;
	transform: translate(-50%, 0rem);
	font-size: 1rem;
	font-weight: 800;
}
.login__modal {
	position: relative;
	width: 50%;
	height: 60vh;
	/* display:flex;
	align-items: center;
	justify-content: center; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}

.signup__modal {
	position: relative;
	width: 50%;
	height: 60vh;
	/* display:flex;
	align-items: center;
	justify-content: center; */
	display: flex;

	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}

/* Manage Order */
.orderdetail__modal {
	position: relative;
	width: 60%;
	height: auto;
	/* display:flex;
	align-items: center;
	justify-content: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}

.showQRCodeModal {
	position: relative;
	width: 400px;
	height: 400px;
	/* display:flex;
	align-items: center;
	justify-content: center; */
	padding: 0.8rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	/* display:flex;
	align-items: center;
	justify-content: center; */
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
}

/* Cart */
.checkoutSummery__modal {
	position: relative;
	width: 80%;
	height: 80vh;
	border: none;
	outline: none;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
}

/* Sales Analysis Modal */
.sales_analysis__modal {
	position: relative;
	width: 80vw;
	height: 80vh;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}
.active_order__modal {
	position: relative;
	width: 90vw;
	height: 90vh;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}
.delete__modal {
	position: relative;
	width: 50%;
	height: auto;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}
.manage__prepline__modal {
	position: relative;
	width: 50%;
	height: 90vh;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}
.all__qr__code__modal {
	position: relative;
	width: 80vw;
	height: 90vh;
	/* display: grid;
		place-items: center; */
	padding: 1rem;
	padding-top: 40px;
	background: white;
	border-radius: 1rem;
	border: none;
	outline: none;
}

@media only screen and (max-width: 480px) {
	.login__modal {
		width: 90%;
		height: auto;
		padding: 0.6rem;
		padding-top: 40px;
		border-radius: 0.6rem;
	}

	.signup__modal {
		width: 90%;
		height: auto;
		padding: 0.6rem;
		padding-top: 40px;
		border-radius: 0.6rem;
	}

	.orderdetail__modal {
		width: 90%;
		height: auto;
		padding: 0.6rem;
		padding-top: 40px;
		border-radius: 0.6rem;
	}

	.sales_analysis__modal {
		width: 90%;
		height: 80vh;
	}
	.active_order__modal {
		width: 90%;
		height: 80vh;
	}

	.delete__modal {
		width: 90%;
	}

	.showQRCodeModal {
		width: 3600px;
		height: 360px;
	}
	.manage__prepline__modal {
		width: 90%;
	}
	.all__qr__code__modal {
		width: 95vw;
		height: 90vh;
		/* padding: 0.4rem; */
	}
}
