body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*,
*::before,
*::after {
	scrollbar-width: thin;
	scrollbar-color: #d1d1d1 white;
}

/* Scroll */
*::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

/* Track */
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px white;
	border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: #d1d1d1;
	border-radius: 0.4rem;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: #a1a1a1;
}
